import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home.vue'
import Dash from '../views/Dash.vue'
import OIDC from '../views/OIDC.vue'
import SAML from '../views/SAML.vue'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/sase/sso/oidc/:sase_cust_no',
    name: 'OIDC',
    component: OIDC
  },
  {
    path: '/sase/sso/saml/:sase_cust_no',
    name: 'SAML',
    component: SAML
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
