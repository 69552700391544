<template>
  <div class="chi-main">
    <div class="chi-main__header">
      <div class="chi-main__header-start">
        <div class="chi-main__title">
          <div class="chi-main__title-heading">SASE Single Sign-On Redirect</div>
        </div>
      </div>
    </div>
    <div class="chi-main__content">
      <div class="chi-grid">
        <div class="chi-col -w--9">
          <fieldset>
            <legend class="chi-label">Authentication Domain Selection</legend>
            <div class="chi-picker">
              <input class="chi-picker__input" name="unique-name-scd" type="checkbox" id="unique-id-scd1" v-model="selectedDomain" true-value="customer" false-value="ccma" @click="domainChange('customer')"/>
              <label for="unique-id-scd1">
                <div class="chi-picker__content--start">
                  <div class="chi-form__item -row">
                    <span class="chi-picker__checkbox"></span>
                    <span class="chi-picker__label">Customer Authentication Domain</span>
                  </div>
                  <div class="chi-picker__description">Authentication and authorization occur using the default Control Center domain. This is the standard customer flow and default for all accounts.</div>
                </div>
              </label>
            </div>
            <div class="chi-picker">
              <input class="chi-picker__input" name="unique-name-scd" type="checkbox" id="unique-id-scd2" v-model="selectedDomain" true-value="ccma" false-value="customer" @click="domainChange('ccma')"/>
              <label for="unique-id-scd2">
                <div class="chi-picker__content--start">
                  <div class="chi-form__item -row">
                    <span class="chi-picker__checkbox"></span>
                    <span class="chi-picker__label">Operations Admin Domain</span>
                  </div>
                  <div class="chi-picker__description">Authentication and authorization occur using Control Center Administration (CCMA) domain. This is the standard operations flow.</div>
                </div>
              </label>
            </div>
          </fieldset>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'Home',
  data() {
    return {
      selectedDomain: (localStorage.getItem('domain') || 'customer')
    }
  },
  methods : {
    domain() {
      return localStorage.getItem('sase.lumen.com.domain') || 'customer'
    },
    domainChange(value) {
      localStorage.setItem('sase.lumen.com.domain', value)
    }
  }
}

</script>
