<template>
  <div class="chi-main">
    <div class="chi-main__header">
      <div class="chi-main__header-start">
        <div class="chi-main__title">
          <div class="chi-main__title-heading">SASE Dashboard</div>
        </div>
      </div>
    </div>
    <div class="chi-main__content">
      <div class="chi-grid">
        <div class="chi-col -w--4 -mt--5">
          <DoughnutChart :chartData="profileData" />
        </div>
        <div class="chi-col -w--6 -mt--5">
          <BarChart :chartData="fortinetData" />
        </div>
        <div v-if="fortinetLicenseData" class="chi-col -w--10 -mt--7">
          <BarChart :chartData="fortinetLicenseData" />
        </div>
        <div class="chi-col -w--12" style="height: 250px;">
          &nbsp;
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { DoughnutChart, BarChart } from 'vue-chart-3';
import { Chart, registerables } from "chart.js";
import GraphQLRepository from "@/repositories/GraphQLRepository.js"

Chart.register(...registerables);

export default {
  name: 'Dash',
  components: { DoughnutChart, BarChart },
  data() {
    return {
      profileData : {
        labels: ['Loading'],
        datasets: [
          {
            data: [0],
            backgroundColor: ['#0079AF'],
          },
        ]
      },
      fortinetData : {
        labels: ['FortiPlanes'],
        datasets: [
          {
            label: 'Available',
            data: [0],
            backgroundColor: '#0079AF',
            indexAxis: 'y'
          },
          {
            label: 'Claimed',
            data: [0],
            backgroundColor: '#54DEFD',
            indexAxis: 'y'
          },
          {
            label: 'Requested',
            data: [0],
            backgroundColor: '#CBB3BF',
            indexAxis: 'y'
          },
        ],
      },
      fortinetLicenseData : null
    }
  },
  mounted() {

    GraphQLRepository.dash().then(res => {

      let vendorLabels = res.data.data.vendors.map(v => v.id)
      let vendorData = res.data.data.vendors.map(v => v.count)

      this.profileData = {
        labels: vendorLabels,
        datasets: [
          {
            data: vendorData,
            backgroundColor: ['#0079AF', '#54DEFD'],
          },
        ]
      }

      let backgroundColors = ['#0079AF', '#54DEFD', '#CBB3BF']

      let fortiplaneDatasets = res.data.data.fortiplanes.map((fp, idx) => {

        return {
          label: fp.id,
          data: [fp.count],
          backgroundColor: backgroundColors[idx],
          indexAxis: 'y'
        }

      })

      fortiplaneDatasets.push(res.data.data.vendors
        .filter(v => v.id == 'Fortinet')
        .map(v => {

          return {
            label: 'Requested',
            data: [v.count],
            backgroundColor: backgroundColors[2],
            indexAxis: 'y'
          }

        })[0])

      this.fortinetData = {
        labels: ['FortiPlanes'],
        datasets: fortiplaneDatasets,
      }

      let licenseLabels = res.data.data.fortinetLicenses.map(v => v.id)
      let licensesAvailable = res.data.data.fortinetLicenses.map(v => v.count)
      let licensesClaimed = res.data.data.fortinetLicenses.map(v => v.claimed)

      this.fortinetLicenseData = {
        labels: licenseLabels,
        datasets: [
          {
            label: 'Available',
            data: licensesAvailable,
            backgroundColor: ['#0079AF'],
            indexAxis: 'y'
          },
          {
            label: 'Claimed',
            data: licensesClaimed,
            backgroundColor: ['#54DEFD'],
            indexAxis: 'y'
          },
        ],
      }

    })

  }
}

</script>
