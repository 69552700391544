<template>
  <div class="chi-main">
    <div class="chi-main__header">
      <div class="chi-main__header-start">
        <div class="chi-main__title">
          <div class="chi-main__title-heading">SSO Exchange</div>
        </div>
      </div>
    </div>
    <div class="chi-main__content">
      <div class="chi-grid">
        <div class="chi-col -w--9">
          <div class="-p--0">
              <div class="chi-alert -info" role="alert" data-cy="cy-sso-oidc-alert">
                  <i class="chi-alert__icon chi-icon icon-circle-info" aria-hidden="true"></i>
                  <div class="chi-alert__content">
                      <p class="chi-alert__text">Processing single-sign-on authorization request.</p>
                  </div>
              </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'OIDC',
  mounted() {

    const domain = localStorage.getItem('sase.lumen.com.domain') || 'customer';

    window.location.replace(
        `/api/v1/oidc/${this.$route.params.sase_cust_no}/redirect?state=${encodeURIComponent(this.$route.query.state)}&client_id=${this.$route.query.client_id}&scope=${this.$route.query.scope}&response_type=${this.$route.query.response_type}&redirect_uri=${this.$route.query.redirect_uri}&sase_cust_no=${this.$route.params.sase_cust_no}&domain=${domain}`
    );

  }
}

</script>
