import Client from './clients/GraphQLClient';

export default {
    dash() {
      var payload = {
          query :`
          {
            vendors {
              id
              count
            }
            fortiplanes {
              id
              count
            }
            fortinetLicenses {
              id
              count
              claimed
            }
          }`
      }
      return Client.post(`/graphql`, payload);
    },
};
